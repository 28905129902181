@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "material-icons/iconfont/material-icons.css";
@import "font-awesome/css/font-awesome.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "bootstrap/scss/bootstrap";
@import "ngx-toastr/toastr";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Lato", sans-serif;
  background-color: #f5f5f5;
}

.ng-select {
  box-shadow: none !important;

  .ng-select-container {
    border: 1px solid #dee2e6 !important;
    min-height: 38px !important;
    height: 3rem !important;

    .ng-placeholder {
      font-size: 16px;
      color: #d4d4d4 !important;
    }

    .ng-value {
      font-size: 16px !important;
      background-color: #fff !important;
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-value .ng-value-icon.left {
          border-right: 1px solid #dcdcdc;
        }
      }
    }
  }

  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {
        .ng-option-label {
          font-size: 15px !important;
        }

        &.ng-option-selected {
          .ng-option-label {
            font-weight: unset;
          }
        }

        &.ng-dropdown-panel {
          .ng-dropdown-panel-items {
            .ng-option.ng-option-selected.ng-option-marked {
              background-color: #0d47a1 !important;

              .ng-option-label {
                font-weight: unset !important;

                &:hover {
                  font-weight: unset !important;
                }
              }
            }
          }
        }
      }
    }
    &.ng-select-bottom {
      margin-top: 10px !important;
    }
  }

  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    box-shadow: none !important;
    border-color: #0d47a1 !important;
  }

  &.ng-select-focused > .ng-select-container {
    box-shadow: none !important;
    border-color: #0d47a1 !important;
  }
}
@layer components {
  .triangle {
    @apply tw-w-[40px] tw-h-[35px];
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }

  .square {
    @apply tw-w-[35px] tw-h-[35px];
  }

  .circle {
    @apply tw-w-[35px] tw-h-[35px] tw-rounded-full;
  }

  .hexagon {
    @apply tw-relative tw-w-[35px] tw-h-[31px];
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  }
}

input[type="number"] {
  -moz-appearance: textfield;
  border-color: inherit;
  &:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// remove bootstrap dropdown default arrow
.dropdown-toggle::after {
  display:none;
}